import {mapActions, mapGetters, mapMutations} from "vuex";
import timer from '@/components/timer/index.vue'

//sections

export default {
    name: "gift",
    components: {
        timer
    },
    data() {
        return {
            hideDelivery:false,
            date: {
                fromDate: '2023-1-20 12:00:00',
                toDate: '1/20/2023'
            },
            payload: {
                name: '',
                phone: '',
                deliveryType: '',
                checked: '',
                city: 'Київ',
                department: ''
            },
            deliveryType: [
                {
                    type: 0,
                    desc: 'Самовивіз: Київ, вул. В.Васильківська 100 (БЦ Торонто, офіс Ковальскої)'

                },
                {
                    type: 1,
                    desc: 'Нова пошта:  за тарифами перевізника'
                },
            ],
            showTimer: false

        }

    },
    watch: {
        'userData'() {
            this.hideDelivery = true
        },
        // userInfo:{
        //     showTimer:true
        // },
        'payload.name'() {
            this.isEmptyFields({status: false, content: this.payload.name})
            let p = this.payload
            if (p.deliveryType.type === 0 && p.name.length >= 6 && p.phone.length === 19 && p.checked) {
                this.isEmptyFields({status: true, content: this.payload})
            }
            if (p.deliveryType.type === 1 && p.name.length >= 6 && p.phone.length === 19 && p.checked && p.city && p.department) {
                this.isEmptyFields({status: true, content: this.payload})
            }
        },
        'payload.phone'() {

            this.isEmptyFields({status: false, content: this.payload.phone})
            let p = this.payload
            if (p.deliveryType.type === 0 && p.name.length >= 6 && p.phone.length === 19 && p.checked) {
                this.isEmptyFields({status: true, content: this.payload})
            }
            if (p.deliveryType.type === 1 && p.name.length >= 6 && p.phone.length === 19 && p.checked && p.city && p.department) {
                this.isEmptyFields({status: true, content: this.payload})
            }
        },
        'payload.deliveryType'() {
            this.isEmptyFields({status: false, content: this.payload.deliveryType})
            let p = this.payload
            if (p.deliveryType.type === 0 && p.name.length >= 6 && p.phone.length === 19 && p.checked) {
                this.isEmptyFields({status: true, content: this.payload})
            }
            if (p.deliveryType.type === 1 && p.name.length >= 6 && p.phone.length === 19 && p.checked && p.city && p.department) {
                this.isEmptyFields({status: true, content: this.payload})
            }
        },
        'payload.checked'() {
            this.isEmptyFields({status: false, content: this.payload.checked})
            let p = this.payload
            if (p.deliveryType.type === 0 && p.name.length >= 6 && p.phone.length === 19 && p.checked) {
                this.isEmptyFields({status: true, content: this.payload})
            }
            if (p.deliveryType.type === 1 && p.name.length >= 6 && p.phone.length === 19 && p.checked && p.city && p.department) {
                this.isEmptyFields({status: true, content: this.payload})
            }
        },
        'payload.city'() {
            this.isEmptyFields({status: false, content: this.payload.city})
            let p = this.payload
            if (p.deliveryType.type === 0 && p.name.length >= 6 && p.phone.length === 19 && p.checked) {
                this.isEmptyFields({status: true, content: this.payload})
            }
            if (p.deliveryType.type === 1 && p.name.length >= 6 && p.phone.length === 19 && p.checked && p.city && p.department) {
                this.isEmptyFields({status: true, content: this.payload})
            }
        },
        'payload.department'() {
            this.isEmptyFields({status: false, content: this.payload.department})
            let p = this.payload
            if (p.deliveryType.type === 0 && p.name.length >= 6 && p.phone.length === 19 && p.checked) {
                this.isEmptyFields({status: true, content: this.payload})
            }
            if (p.deliveryType.type === 1 && p.name.length >= 6 && p.phone.length === 19 && p.checked && p.city && p.department) {
                this.isEmptyFields({status: true, content: this.payload})
            }
        }
    },
    created() {
    },
    mounted() {
        if(!this.userInfo){
            this.getUserInfo()
        }


        this.onRequestTown(this.payload.city)
    },
    computed: {
        ...mapGetters({
            towns: 'order/towns',
            departments: 'order/departments',
            userInfo: 'game/userInfo',
            userData: 'order/userData',
        }),
        isShowDelivery(){
            if(this.userInfo.phone){
                return false
            }else{
                return  this.hideDelivery
            }
        }
    },

    methods: {
        ...mapActions({
            fetchTowns: 'order/NOVA_POSHTA_GET_TOWNS',
            fetchDepartments: 'order/NOVA_POSHTA_GET_DEPARTMENS',
            getUserInfo: 'game/GET_USER_INFO',
        }),
        ...mapMutations({
            setDepartments: 'order/NOVA_POSHTA_SET_DEPARTMENS',
            isEmptyFields: 'setting/SET_DELIVERY_DATA'
        }),
        onRequestTown(val) {
            const formData = JSON.stringify({
                "modelName": "Address",
                "calledMethod": "getCities",
                "methodProperties": {
                    "FindByString": val
                },
                "apiKey": process.env.VUE_APP_NOVAPOSHTA_API_KEY
            })
            this.fetchTowns(formData).then(() => {
                const currentTown = this.towns.find(e => e.Description === this.payload.city)
                this.department = ''

                if (!currentTown) {
                    return
                }

                if (this.payload.city === currentTown.Description) {
                    this.onRequestDepartments('')
                } else {
                    this.setDepartments([])
                }
                this.checkValidData()
            })
        },
        onRequestDepartments(val) {
            const formData = JSON.stringify({
                "modelName": "AddressGeneral",
                "calledMethod": "getWarehouses",
                "methodProperties": {
                    "CityName": this.payload.city,
                    "FindByString": val,
                    // "Language": "ua"
                },
                "apiKey": process.env.VUE_APP_NOVAPOSHTA_API_KEY
            })
            this.fetchDepartments(formData).then(() => {

            })
        },
        checkValidData() {
            const currentTown = this.towns.find(e => e.Description === this.payload.city)
            if (currentTown) {
                this.isValidTown = this.payload.city === currentTown.Description && this.payload.city.length !== 0
            } else {
                this.isValidTown = false
            }

            const currentDepartment = this.departments.find(e => e.Description === this.payload.department)
            if (currentDepartment) {
                this.isValidDepartment = this.payload.department === currentDepartment.Description && this.payload.department.length !== 0
            } else {
                this.isValidDepartment = false
            }

            this.isValid = this.isValidTown && this.isValidDepartment;
            this.$emit('input', this.isValid);
        },

    }
}
